<template>
  <div class="container-faqs">
    <v-container>
      <v-row class="justify-md-center">
        <v-col cols="12" sm="12" md="6" lg="8">
          <h3 class="text-h3 black--text text-center mb-8">FAQ´s</h3>
          <v-expansion-panels class="list-faqs">
            <v-expansion-panel
              class="mb-6"
              v-for="(pregunta, index) in listPreguntas"
              :key="index"
            >
              <v-expansion-panel-header>
                <p class="text-h6 mb-0">{{ pregunta.titulo }}</p>
                <template v-slot:actions>
                  <div class="icon-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.828"
                      height="6.414"
                      viewBox="0 0 10.828 6.414"
                    >
                      <path
                        data-name="Trazado 1715"
                        d="m0 0 4 4-4 4"
                        transform="rotate(90 4 5.414)"
                      />
                    </svg>
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p class="text-body-2 mb-0">{{ pregunta.texto }}</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      listPreguntas: [
        {
          titulo: "¿Pregunta número uno? ",
          texto:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
        },
        {
          titulo: "¿Pregunta número dos? ",
          texto:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
        },
        {
          titulo: "¿Pregunta número tres? ",
          texto:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
        },
        {
          titulo: "¿Pregunta número cuatro? ",
          texto:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.container-faqs {
  width: 100%;
  padding-top: 150px;
  padding-bottom: 80px;
  .list-faqs {
    .v-expansion-panel {
      border: 1px solid #000000;
       border-radius: 5px;
      &:before {
        box-shadow: none;
      }
      .v-expansion-panel-content__wrap {
        position: relative;
        padding: 16px 24px 16px;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 100%;
          background-color: #2b604b;
          display: none;
        }
      }
      &.v-item--active {
        .icon-arrow {
          background-color: #2b604b;
          border-color: #2b604b;
          transform: rotate(180deg);
          svg {
            stroke: #ffffff;
          }
        }
        .v-expansion-panel-content__wrap {
          border-top: 1px solid #000;
          &:before {
            display: block !important;
          }
        }
      }
    }
  }
}
.icon-arrow {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  svg {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
}
</style>